import componentInitializer from "@/frontend/utils/componentInitializer"
import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"

componentInitializer(() => {
  const el = document.getElementById("nav-guest-favourite")

  if (el) {
    const component = defineAsyncComponent(() => import("@/components/Nav/Guest/Favourite.vue"))

    window.GuestFavourite = new Vue({
      el,
      store,
      apolloProvider,
      render: (h) =>
        h(component, {
          props: el.dataset,
        }),
    })
  } else {
    // User is logged in, reset guest
    store.dispatch("guest/reset")
  }
})

window.setGuestFavouriteIds = () => {
  const favouriteIds = store.state.guest.favourites.map((l) => l.id).join(",")

  const target = document.getElementById("add_favourites")
  if (target) target.value = favouriteIds

  const oauthLoginIds = ["fb-login", "google-login"]
  oauthLoginIds.forEach((oauthLoginId) => {
    const target = document.getElementById(oauthLoginId)
    if (target) {
      const url = new URL(target.href)
      url.searchParams.append("add_favourites", favouriteIds)
      target.href = url
    }
  })
}

store.subscribe((mutation, state) => {
  if (mutation?.type === "guest/SET_FAVOURITES") {
    window.setGuestFavouriteIds()
  }
})
componentInitializer(window.setGuestFavouriteIds)
